import payment from "@/views/individual-center/my-account/payment/payment.vue"

const key = {
  default: '/gateway', // 默认代理前缀
  image: '/image-proxy', // 加载图片
  // 下载文件, CDN enabled
  downloadFile: process.env.NODE_ENV === 'production'
    ? 'https://awsdownload.wisemodel.cn/file-proxy'
    : 'https://devawsdownload.wisemodel.cn/file-proxy'
}

const prefix = {
  project: '/project/api/v1', // 项目
  user: '/user/api/v1', // 用户
  notice: '/notice/api/v1', // 我的
  organize: '/organize/api/v1', // 组织
  collect: '/collect/api/v1', // 收藏
  resource: '/resource/api/v1', // 资源
  file: '/fileupload/api/v1', // 文件
  comment: '/comment/api/v1', // 建议
  storage:'/storage/api/v1'
}

// mock地址
// for (const key in prefix) {
//   prefix[key] = '/mock/20' + prefix[key]
// }

export default {
  key,
  accountinfo: key.default + '/accountinfo', // 账户信息
  baseinfo: key.default + '/baseinfo', // 基本信息
  organize: key.default + '/organize', // 新建组织
  member: key.default + '/member', // 邀请成员加入
  // 文件
  file: {
    check: key.default + prefix.file + '/check', // 检查
    upload: key.default + prefix.file + '/upload', // 分片上传
    merge: key.default + prefix.file + '/merge', // 合并文件上传
    downupload: key.default + prefix.file + '/downupload', // 下载
    createfolder: key.default + prefix.file + '/createfolder', // 创建文件夹
    addfiles: key.default + prefix.file + '/addfiles' // 增加文件
  },
  // 用户
  user: {
    login: key.default + prefix.user + '/login', // 登录
    register: key.default + prefix.user + '/register', // 注册
    sendCode: key.default + prefix.user + '/code/send', // 发送验证码
    forgetPassword: key.default + prefix.user + '/update/password', // 忘记密码
    verifyCode: key.default + prefix.user + '/code/verify', // 短信验证码验证
    putInfoNoToken: key.default + prefix.user + '/account/info', // 账号更新(注册完成后完善信息页面用，无需token)
    putInfoNeedToken: key.default + prefix.user + '/info', // 账号更新（修改基本信息用，需要token）
    uploadAvatar: key.default + '/upload//api/v1/avatar', // 上传头像
    query: key.default + prefix.user + '/query', // 查询用户列表
    checkPhone: key.default + prefix.user + '/check/phone',// 验证手机号是否已注册
    createCaptcha: key.default + prefix.user + '/register/createCaptcha' // 注册验证码
  },
  // 消息
  notice: {
    list: key.default + prefix.notice + '/list', // 我的消息
    add: key.default + prefix.notice + '/add', // 创建消息
    edit: key.default + prefix.notice + '/edit', // 更新消息
    get: key.default + prefix.notice + '/get', // 获取消息详情
    unreadCount: key.default + prefix.notice + '/count/unread' // 获取未读消息数
  },
  // 收藏
  collect: {
    add: key.default + prefix.collect + '/add', // 添加收藏
    del: key.default + prefix.collect + '/del', // 取消收藏
    list: key.default + prefix.collect + '/list' // 我的收藏
  },
  // 组织
  organize: {
    get: key.default + prefix.organize + '/get', // 查询组织详情
    add: key.default + prefix.organize + '/add', // 创建组织
    del: key.default + prefix.organize + '/del', // 删除组织
    put: key.default + prefix.organize + '/put', // 更新组织
    list: key.default + prefix.organize + '/list', // 我的组织
    invite: key.default + prefix.organize + '/invite', // 邀请加入组织
    remove: key.default + prefix.organize + '/remove', // 移除组织成员
    queryorganizelist: key.default + prefix.organize + '/listWithProjectNum' // 所有组织列表
  },
  // 资源
  resource: {
    list: key.default + prefix.resource + '/list' // 我的组织
  },
  // 建议
  comment: {
    add: key.default + prefix.comment + '/add' // 添加建议
  },
  
  // 项目(模型、数据集)
  project: {
    // 创建模型
    getOptions: key.default + prefix.project + '/options', // 下拉选项options
    getModelImages: key.default + prefix.project + '/getModelImagesForDemo', // 体验镜像列表下拉选项options
    getModelImagesapi: key.default + prefix.project + '/getModelImagesForapi', // api镜像列表下拉选项options
    getTimeRange: key.default + prefix.project + '/getTimeRangeList', // 休眠时间列表下拉选项options
    getXPUInfoList: key.default + prefix.project + '/getXPUInfoList', // 获取xpu信息列表下拉选项options
    getXPUInfosByImageId:key.default + prefix.project + '/getXPUInfosByImageId',//动态获取xpu信息
    addModel: key.default + prefix.project + '/add', // 基础信息
    setAttr: key.default + prefix.project + '/attr', // 设置属性
    addlabel: key.default + prefix.project + '/addlabel', // 添加标签
    related: key.default + prefix.project + '/related', // 关联模型数据集
    cancelrelated: key.default + prefix.project + '/cancelrelated', // 取消关联模型数据集
    delproject: key.default + prefix.project + '/delproject', // 删除项目
    projectzan: key.default + prefix.project + '/projectzan', // 点赞
    projectcancelzan: key.default + prefix.project + '/projectcancelzan', // 取消点赞
    projectcollect: key.default + prefix.project + '/projectcollect', // 收藏
    projectcancelcollect: key.default + prefix.project + '/projectcancelcollect', // 取消收藏
    // 模型详情
    queryprojectlist: key.default + prefix.project + '/queryprojectlist', // 模型列表
    relatelist: key.default + prefix.project + '/relatelist', // 查询项目关联列表
    branchlist: key.default + prefix.project + '/branchlist', // 查询项目分支列表
    queryprojectlist: key.default + prefix.project + '/queryprojectlist', // 模型列表
    queryprojectinfo: key.default + prefix.project + '/queryprojectinfo', // 查询项目详情
    commithistory: key.default + prefix.project + '/commithistory', // 文件提交commit记录
    listfiles: key.default + prefix.project + '/listfiles', // 展示文件
    createissue: key.default + prefix.project + '/createissue', // 创建问题
    issuelist: key.default + prefix.project + '/issuelist', // 问题列表
    creatediscuss: key.default + prefix.project + '/creatediscuss', // 创建讨论
    discusslist: key.default + prefix.project + '/discusslist', // 讨论列表
    removediscuss: key.default + prefix.project + '/removediscuss', // 删除评论
    addbranch: key.default + prefix.project + '/addbranch', // 增加分支
    addtag: key.default + prefix.project + '/addtag', // 增加分支标签
    getProjectWangpanurl: key.default + prefix.project + '/getProjectWangpanurl', // 查询项目详情
    getResourceInfoList: key.default + prefix.project + '/getResourceInfoList', // 查询项目详情
    spaceList: key.default + prefix.project + '/getModelServings', // 体验列表
    spaceTopList: key.default + prefix.project + '/gettopmodelservings', // 热门体验列表
    getModelServing: key.default + prefix.project + '/getModelServing', // 热门体验详情
    createModelServing: key.default + prefix.project + '/createModelServingDemo', // 创建模型服务demo
    renewModelServing:key.default + prefix.project + '/renewModelServing',//模型体验续费
    getAccountInfo: key.default + prefix.project + '/getAccountInfo', // 个人账户信息
    modelservingzan: key.default + prefix.project + '/modelservingzan', // 模型服务点赞
    modelservingcollect: key.default + prefix.project + '/modelservingcollect', // 模型服务收藏
    modelservingcancelzan: key.default + prefix.project + '/modelservingcancelzan',
    modelservingcancelcollect: key.default + prefix.project + '/modelservingcancelcollect',
    modelservinghaszan: key.default + prefix.project + '/modelservinghaszan',
    modelservinghascollect: key.default + prefix.project + '/modelservinghascollect',
    getxpuCharg: key.default + prefix.project + '/getXPUMSChargingTypeByXPUId', // 获取xpu计费配置列表
    getModelRelateModelServings: key.default + prefix.project + '/getModelRelateModelServings',
    startupModelServing: key.default + prefix.project + '/startupModelServing',
    stopModelServing: key.default + prefix.project + '/stopModelServing',
    checkPay:key.default + prefix.project + '/getAccountChargeOrderByOutTradeNo?outTradeNo=',//查询支付结果
    uploadMSImage: key.default + prefix.project + '/uploadMSImage',//选择图片上传
    uploadMSChat:key.default + prefix.project + '/uploadMSChat',//对话信息上传
    getPersonModelServings:key.default + prefix.project + '/getPersonModelServings',
    getCollectedModelServings:key.default + prefix.project + '/getCollectedModelServings',//收藏模型详情
    modelServingAccessCount:key.default + prefix.project + '/modelServingAccessCount',
    createModelServingAPI:key.default + prefix.project + '/createModelServingAPI',//创建API服务
    getCurrentMSTimeStamp:key.default + prefix.project + '/getCurrentMSTimeStamp',//获取服务器时间
    getFileContent:key.default + prefix.project + '/getFileContent',//获取gitlab文件内容
    verifyGpuIsEnough:key.default + prefix.project + '/verifyGpuIsEnough',//验证gpu资源是否
    verifyProjAccess:key.default + prefix.project + '/verifyProjAccess',//验证是否有权限访问模型代码数据集详情
    //订单
    getModelServingChargings: key.default + prefix.project + '/getModelServingChargings', // 获取个人账单
    getAccountChargeOrders: key.default + prefix.project + '/getAccountChargeOrders',//获取充值记录
    wxpayMent:key.default + prefix.project + '/native/getWxCodeUrl',  //支付接口
    setPersonMSVisable: key.default + prefix.project +'/setPersonMSVisable',//服务是否公开
    getModelServingOrders: key.default + prefix.project +'/getModelServingOrders',//获取个人模型订单服务
    cancleModelServingOrder:key.default + prefix.project +'/cancleModelServingOrder',//取消订单  (个人中心订单详情中未生效的订单)
    
    //apitoken
    addMSAPIKey:key.default + prefix.project +'/addMSAPIKey',//添加apikey
    getMSAPIKeys:key.default + prefix.project +'/getMSAPIKeys',//获取apikey列表
    delMSAPIKey:key.default + prefix.project +'/delMSAPIKey',//删除apikey
    setDefaultMSAPIKey:key.default + prefix.project +'/setDefaultMSAPIKey',//设置默认apikey
    getModelImagesForTrain:key.default + prefix.project +'/getModelImagesForTrain',//获取训练用镜像列表
    getXPUInfosByImageIdForTrain:key.default + prefix.project +'/getXPUInfosByImageIdForTrain',//获取训练用资源配置列表
    //开发环境创建
    createModelDevAPI:key.default + prefix.project +'/createModelDevAPI',//创建开发环境
    getModelDevDetail:key.default + prefix.project +'/getModelDevDetail',//开发环境详情接口
    getModelDevOrder:key.default + prefix.project +'/getModelDevOrder',//获取开发环境订单
    getModelDevChargings:key.default + prefix.project +'/getModelDevChargings',//获取开发环境消费记录
    getRealTimeLog:key.default + prefix.project +'/getRealTimeLog',//获取实时日志
    getHistoryTimeLog:key.default + prefix.project +'/getHistoryTimeLog',//获取开发环境历史日志
    stopModelDev:key.default + prefix.project +'/stopModelDev',//停止开发环境
    reStartModelDev:key.default + prefix.project +'/reStartModelDev',//重新启动启动开发环境
    reInitModelDev:key.default + prefix.project +'/reInitModelDev',//重试开发环境
    setUserStorage:key.default + prefix.project +'/setUserStorage',//个人存储扩缩容
    getGpuUtilization:key.default + prefix.project +'/getGpuUtilization',//获取gpu利用率
    getCpuUtilization:key.default + prefix.project +'/getCpuUtilization',//获取cpu利用率
    getMemUtilization:key.default + prefix.project +'/getMemUtilization',//获取内存利用率
    reportUserClickWCDC:key.default + prefix.project +'/reportUserClickWCDC',//访问开发者大会页面统计
    verifyMSAccess:key.default + prefix.project +'/verifyMSAccess',//服务权限校验
    
    reportUserClickEvent:key.default + prefix.project +'/reportUserClickEvent',//统计功能访问次数
    getuserjurisdiction:key.default + prefix.project +'/getuserjurisdiction',//校验用户是否有权限访问详情
    getInnerServiceDomains:key.default + prefix.project +'/getInnerServiceDomains',//获取已申请内置服务端口
    delInnerServiceDomain:key.default + prefix.project +'/delInnerServiceDomain',//废弃内置服务端口
    applyInnerServiceDomain:key.default + prefix.project +'/applyInnerServiceDomain',//申请内置服务端口
    //个人存储
    getClusterInfosByUserStorage:key.default + prefix.project +'/getClusterInfosByUserStorage',//获取个人存储关联的集群
    //开发环境主页
    getModelDevList:key.default + prefix.project +'/getModelDevList',//开发环境列表
  },
  storage:{
    //开发环境
    getShareFileList:key.default + prefix.storage , //获取存储目录列表
    getShareModelDir:key.default + prefix.storage ,//获取开发环境指定的目录的列表
    getUserDir:key.default + prefix.storage ,//获取个人目录
    fileUploadCheck:key.default + prefix.storage ,//文件检查
    fileUpload:key.default + prefix.storage ,//文件分片上传
    fileMerge:key.default + prefix.storage ,//文件合并上传
    createFolder:key.default + prefix.storage ,//创建指定目录下的文件夹
    delFiles:key.default + prefix.storage ,//删除指定目录下的文件夹或文件
    renameFile:key.default + prefix.storage ,//文件重命名
  }
}
