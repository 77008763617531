export const header = {
  home: 'Home',
  model: 'Model',
  datasets: 'Datasets',
  codes: 'Codes',
  spaces: 'Apps',
  cp: 'Cp',
  docs:'Docs',
  donation:'Donation'
}
export const home ={
  
}