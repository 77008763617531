import path from '@/api/path'
import Layout from '@/components/layout'

// 基础路由
const baseRoutes = [
  {
    path: '/',
    redirect: '/layout'
  }
]

// 菜单路由
export const menuRoutes = [
  {
    path: '/home',
    name: 'home',
    meta: { title: '首页' },
    component: () => import('@/views/home/index.vue'),
    meta:{
      metaInfo:{
        ogTitle: 'Wisemodel 始智AI 社区',
        ogDescription: '始智AI wisemodel.cn社区将从打造中国版huggingface开始，逐步建设成huggingface之外最活跃的中立开放的AI开源社区，汇聚模型、数据集等AI开源技术资源，并在此在打造新一代AI基础设施服务平台，为AI产业发展提供有力的平台支撑，让AI更简单，促进AI开源创新生态繁荣发展。',
        ogImage: 'https://wisemodel-avatar.bj.bcebos.com/favicon.ico',
        ogUrl:'//wisemodel.cn/'
      }
     
    }
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    meta: { title: '首页' },
    component: () =>
      import('@/components/layout/header/login/forget-password/index.vue')
  },
  {
    path: '/code',
    name: 'code',
    redirect: '/codes',
    meta: { title: '代码' },
    component: () => import('@/views/code/index.vue'),
    children: [
      {
        path: '/codes',
        name: 'codes',
        meta: { title: '代码首页' },
        component: () => import('@/views/code/home.vue')
      },
      {
        path: '/codes/:username_en/:project_name_en/:tab?',
        name: 'code-detail',
        meta: { title: '代码详情' },
        component: () => import('@/views/model/detail/index.vue'),
        children:[
          {
            path:'/codes/:username_en/:project_name_en/:tab?/:breach_name/:file_name+',
            name:'file-detail-C',
            meta: { title: '代码文件详情' },
            component: () => import('@/views/model/detail/filedetail.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '/model-dev/',
    name: 'model-dev',
    meta: { title: '后台' },
    component: () => import('@/views/backstage/index.vue'),
    children: [
      {
        path: '/model-dev/:routerName/home',
        name: 'model-devs',
        meta: { title: '后台页',requiresAuth: true },
        component: () => import('@/views/backstage/home.vue'),
      },
      {
        path: '/model-dev/:routerName/envmanager/:modelTag/:tab?',
        name: 'envmanager',
        meta: { title: '后台页',requiresAuth: true },
        component: () => import('@/views/backstage/envmanager/home.vue'),
      },
      {
        path: '/model-dev/:routerName/filemanager/:filetype?',
        name: 'filemanager',
        meta: { title: '文件管理',requiresAuth: true },
        component: () => import('@/views/backstage/filemanager/index.vue'),
      },
    ]
  },
  {
    path: '/no-permission',
    name:'NoAccessPage',
    component: () => import('@/views/no-permission/index.vue'),
  },
  {
    path: '/model',
    name: 'model',
    redirect: '/models',
    meta: { title: '模型' },
    component: () => import('@/views/model/index.vue'),
    children: [
      {
        path: '/models',
        name: 'models',
        meta: { 
          title: '模型首页' ,
          ogTitle: 'Wisemodel 模型列表页',
          ogDescription: '始智AI wisemodel.cn社区将从打造中国版huggingface开始，逐步建设成huggingface之外最活跃的中立开放的AI开源社区，汇聚模型、数据集等AI开源技术资源，并在此在打造新一代AI基础设施服务平台，为AI产业发展提供有力的平台支撑，让AI更简单，促进AI开源创新生态繁荣发展。',
          ogImage: '//wisemodel-avatar.bj.bcebos.com/favicon.ico',
          ogUrl:'//wisemodel.cn/models'
        },
        component: () => import('@/views/model/home.vue')
      },
      {
        path: '/models/:username_en/:project_name_en/:tab?',
        //path: '/:username_en/:project_name_en/:tab?',
        name: 'model-detail',
        meta: { title: '模型详情' },
        component: () => import('@/views/model/detail/index.vue'),
        children:[
          {
            path:'/models/:username_en/:project_name_en/:tab?/:breach_name/:file_name+',
            name:'file-detail-M',
            meta: { title: '文件详情' },
            component: () => import('@/views/model/detail/filedetail.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '/EdgeAIs',
    name: 'Edgeais',
    redirect: '/EdgeAI',
    meta: { title: '端侧模型' },
    component: () => import('@/views/EdgeAI/index.vue'),
    children: [
      {
        path: '/EdgeAI',
        name: 'EdgeAI',
        component: () => import('@/views/EdgeAI/home.vue')
      },
      {
        path: '/EdgeAI/:username_en/:project_name_en/:tab?',
        //path: '/:username_en/:project_name_en/:tab?',
        name: 'EdgeaiM-detail',
        meta: { title: '模型详情' },
        component: () => import('@/views/model/detail/index.vue'),
        children:[
          {
            path:'/EdgeAI/:username_en/:project_name_en/:tab?/:breach_name/:file_name+',
            name:'EdageAIFiledetail',
            meta: { title: '端测文件详情' },
            component: () => import('@/views/model/detail/filedetail.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '/dataset',
    name: 'dataset',
    redirect: '/datasets',
    meta: { title: '数据集' },
    component: () => import('@/views/dataset/index.vue'),
    children: [
      {
        path: '/datasets',
        name: 'datasets',
        meta: { title: '数据集首页' },
        component: () => import('@/views/model/home.vue')
      },
      {
        path: '/datasets/:username_en/:project_name_en/:tab?',
        name: 'dataset-detail',
        meta: { title: '数据集详情' },
        component: () => import('@/views/model/detail/index.vue'),
        children:[
          {
            path:'/datasets/:username_en/:project_name_en/:tab?/:breach_name/:file_name+',
            name:'file-detail-D',
            meta: { title: '数据集文件详情' },
            component: () => import('@/views/model/detail/filedetail.vue'),
          }
        ]
      }
    ]
  },
  {
    path:'/WCDC',
    name:'anniversary',
    meta: { title: '活动报名' },
    component: () => import('@/views/anniversary/index.vue'),
  },
  {
    path: '/docs/:name?',
    name: 'docs',
    meta: { title: '文档' },
    component: () => import('@/views/docs/index.vue')
  },
  {
    path: '/suggestion',
    name: 'suggestion',
    meta: { title: '建议' },
    component: () => import('@/views/suggestion/index.vue')
  },
  {
    path: '/individual-center',
    name: 'individual-center',
    meta: { title: '个人中心' },
    redirect: '/individual-overview',
    component: () => import('@/views/individual-center/index.vue'),
    children: [
      {
        path: '/individual-overview/:tab?',
        name: 'individual-overview',
        meta: { title: '个人概况' },
        component: () =>
          import('@/views/individual-center/individual-overview/index.vue')
      },
      {
        path: '/my-message/:tab?',
        name: 'my-message',
        meta: { title: '我的消息' },
        component: () =>
          import('@/views/individual-center/my-message/index.vue')
      },
      {
        path: '/my-collect/:tab?',
        name: 'my-collect',
        meta: { title: '我的收藏' },
        component: () =>
          import('@/views/individual-center/my-collect/index.vue')
      },
      {
        path: '/my-organization/:tab?',
        name: 'my-organization',
        meta: { title: '我的组织' },
        component: () =>
          import('@/views/individual-center/my-organization/index.vue')
      },
      {
        path: '/my-resources/:tab?',
        name: 'my-resources',
        meta: { title: '我的资源' },
        component: () =>
          import('@/views/individual-center/my-resources/index.vue')
      },
      {
        path: '/my-token/:tab?',
        name: 'my-token',
        meta: { title: '我的Token' },
        component: () =>
          import('@/views/individual-center/my-token/index.vue')
      },
      {
        path: '/my-account/:tab?',
        name: 'my-account',
        meta: { title: '财务信息' },
        component: () =>
          import('@/views/individual-center/my-account/index.vue')
      }
    ]
  },
  {
    path: '/organization/:name?',
    name: 'organization',
    meta: { title: '组织' },
    component: () => import('@/views/organization/index.vue')
  },
  {
    path: '/training',
    name: 'training',
    meta: { title: '算力' },
    component: () => import('@/views/cp/index.vue')
  },
  {
    path: '/modelspaces',
    name: 'modelspaces',
    redirect: '/apps',
    meta: { title: '体验' },
    component: () => import('@/views/spaces/index.vue'),
    children: [
      {
        path: '/apps',
        name: 'apps',
        meta: { title: '体验首页' },
        component: () => import('@/views/spaces/apps')
      },
      {
        path: '/spaces',
        name: 'spaces',
        meta: { title: '体验首页' },
        component: () => import('@/views/spaces/show-model-list.vue')
      },
      {
        path: '/spaces/:routerName/:modelTag',
        name: 'modelspacesdetail',
        meta: { title: '体验详情' },
        component: () => import('@/views/spaces/show-model-detail.vue')
      },
      {
        path: '/space',
        name: 'space',
        meta: { title: '体验首页' },
        component: () => import('@/views/spaces/model-list.vue')
      },
      {
        path: '/space/:routerName/:modelTag',
        name: 'modelspacesdetail',
        meta: { title: '体验详情' },
        component: () => import('@/views/spaces/model-detail.vue')
      }
    ]
  },
  {
    path: '/apispaces',
    name: 'apispaces',
    redirect: '/api',
    meta: { title: 'API服务' },
    component: () => import('@/views/apispaces/index.vue'),
    children: [
      {
        path: '/api',
        name: 'api',
        meta: { title: 'api服务首页' },
        component: () => import('@/views/apispaces/apisp.vue')
      },
      {
        path: '/api/:routerName/:modelTag',
        name: 'apispacesdetail',
        meta: { title: 'api详情' },
        component: () => import('@/views/apispaces/apidetal.vue')
      },

    ]
  },
  {
    path: '/LMSC',
    name: 'LMSC',
   
    meta: { title: 'API服务' },
    component: () => import('@/views/LMSC/home.vue'),
    // children: [
    //   {
    //     path: '/LMSCs',
    //     name: 'LMSCs',
    //     meta: { title: 'LMSC页' },
    //     component: () => import('@/views/LMSC/home.vue')
    //   },
    // ]
  }
]

export const routes = baseRoutes.concat([
  {
    path: '/layout',
    name: 'layout',
    meta: { title: '始智AI云平台' },
    component: Layout,
    redirect: '/home',
    children: menuRoutes
  }
])
